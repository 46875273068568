<template>
  <div class="g19-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g19Service from '@/services/g19Service'
import prepareMaterialListService from '@/services/prepareMaterialListService'
import auth from '@/common/auth'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G19TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '材料备料日统计汇总表',
        columns: [
          {
            label: '序号',
            dataKey: 'materialListCode',
            width: '50'
          },
          {
            label: '材料分类',
            dataKey: 'parentMaterialListName',
            width: 200,
            autoMerge: true
          },
          {
            label: '材料名称',
            dataKey: 'materialListName',
            width: 200
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          }
        ]
      },
      defaultDataList: [],
      materialList: [],
      tenderStatList: [],
      materialMap: {},
      otherTenderStats: [],
      reportDate: '',
      emptyColumns: [],
      engineeringType: this.$route.query.engineeringType || '土建'
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      console.log(this.$refs.mySpread)
      this.$refs.mySpread.exportExcel('主要材料备料日统计汇总表.xlsx')
    },
    generateMapData () {
      this.materialList.forEach(item => {
        this.materialMap[item.materialListCode] = {
          materialListGuid: item.materialListGuid,
          materialListCode: item.materialListCode,
          materialListName: item.materialListName,
          parentMaterialListName: item.parentMaterialList.materialListName,
          unit: item.unit,
          sortId: item.sortId
        }
      })

      let tenderNames = {}
      this.tenderStatList.forEach(item => {
        if (!tenderNames[item.tenderName]) {
          const column = {
            label: item.tenderName === 'total' ? '汇总' : item.tenderName,
            children: [
              {
                label: '合同量',
                dataKey: `${item.tenderName}_num`,
                width: 100
              },
              {
                label: '本月计划',
                dataKey: `${item.tenderName}_currentMonthPlanNum`,
                width: 100
              },
              {
                label: '本日完成',
                dataKey: `${item.tenderName}_currentDateStatNum`,
                width: 100
              },
              {
                label: '本月累计',
                dataKey: `${item.tenderName}_currentMonthStatNum`,
                width: 100
              },
              {
                label: '本月累计占月计划',
                dataKey: `${item.tenderName}_currentMonthStatPercent`,
                width: 100
              },
              {
                label: '累计累计',
                dataKey: `${item.tenderName}_totalStatNum`,
                width: 100
              },
              {
                label: '累计累计占合同',
                dataKey: `${item.tenderName}_totalStatPercent`,
                width: 100
              }
            ]
          }
          this.spreadInfo.columns.push(column)

          tenderNames[item.tenderName] = 1
        }
        console.log('item', item)
        this.materialMap[item.materialListCode][`${item.tenderName}_num`] = item.num
        this.materialMap[item.materialListCode][`${item.tenderName}_currentMonthPlanNum`] = item.currentMonthPlanNum
        this.materialMap[item.materialListCode][`${item.tenderName}_currentDateStatNum`] = item.currentDateStatNum
        this.materialMap[item.materialListCode][`${item.tenderName}_currentMonthStatNum`] = item.currentMonthStatNum
        this.materialMap[item.materialListCode][`${item.tenderName}_totalStatNum`] = item.totalStatNum
        this.materialMap[item.materialListCode][`${item.tenderName}_currentMonthStatPercent`] = item.currentMonthStatPercent
        this.materialMap[item.materialListCode][`${item.tenderName}_totalStatPercent`] = item.totalStatPercent
      })

      for (let key of Object.keys(this.materialMap)) {
        const item = this.materialMap[key]
        this.defaultDataList.push(item)
      }

      this.$loading().close()
    },
    getMaterialList () {
      prepareMaterialListService.projectList(auth.getUserInfo().projectGuid, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.materialList = res.data.data.filter(item => item.parentId !== 0)
            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      this.otherTenderStats = []
      this.spreadInfo.columns = [
        {
          label: '序号',
          dataKey: 'materialListCode',
          width: '50'
        },
        {
          label: '材料类别',
          dataKey: 'parentMaterialListName',
          width: 200,
          autoMerge: true
        },
        {
          label: '材料名称',
          dataKey: 'materialListName',
          width: 200
        },
        {
          label: '单位',
          dataKey: 'unit',
          width: 90
        }
      ]
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      g19Service.getDateTenderStat(this.reportDate, auth.getUserInfo().projectGuid, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data

            this.getMaterialList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.g19-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
